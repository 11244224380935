.nav-main {
  width: 100%;
  min-height: 160px;
}

.nav-logo {
  margin-left: 5.1%;
  margin-top: 53px;
  margin-right: 5.1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-group {
  width: 30%;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
}

.Link {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.Link:hover {
  color: rgb(145, 145, 145);
}

@media only screen and (max-width: 767px) {
  .nav-logo :first-child :first-child {
    display: none;
  }

  .nav-main {
    display: none;
  }
}
