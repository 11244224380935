.project-main {
  border: rgb(255, 230, 0) solid;
  width: 100%;
  height: 100%;
}

.pro-section1 {
  height: 50vmax;
  /* height: 891px; */
  background-image: url("../../../data/Data/N11/N11 Lo-res/DSC_4093.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.pro-section2 {
  border-bottom: #fff solid 1px;
}

.pro-image {
  width: 100%;
  max-height: 450px;
}

.pro-header {
  font-size: 32px;
  font-family: PoppinsSemiBold;
  margin: 20px 0px;
  color: #fff;
  text-align: left;
}

.pro-p1 {
  font-size: 23px;
  font-family: PoppinsLight;
  color: #fff;
  text-align: left;
}
