.App {
  text-align: center;
  background-color: #020001;
}

.App-Main {
  width: 100%;
}

.row {
  margin: 0px;
  padding: 0px;
  width: 100%;
  background-color: #020001;
}

.container-menu {
  position: absolute;
  width: 100%;
  height: 50vmax;
  /* height: 891px; */
  background: rgb(2, 0, 1);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 1, 1) 1%,
    rgba(2, 0, 1, 0.6) 20%,
    rgba(2, 0, 1, 0) 40%
  );
  z-index: 5;
}

/* --------------------------------- Project Details ------------------------------------ */

.de-header {
  font-size: 29px;
  font-family: PoppinsSemiBold;
  color: #ffffff;
  padding: 60px 0px;
}

.de-projectName {
  font-size: 26px;
  font-family: PoppinsSemiBold;
  color: #ffffff;
}

.de-loEN {
  font-size: 18px;
  font-family: PoppinsLight;
  color: #ffffff;
  margin-bottom: 20px;
}

.de-loTH {
  font-size: 19px;
  font-family: PlexSansRegular;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: left;
}

.de-work {
  font-size: 19px;
  font-family: PlexSansBold;
  color: #ffffff;
  text-align: left;
  padding-right: 1rem !important;
}

.de-work-detail {
  font-size: 19px;
  font-family: PlexSansRegular;
  color: #ffffff;
  text-align: left;
}

.de-san-bold {
  font-size: 19px;
  font-family: PlexSansBold;
  color: #ffffff;
  text-align: left;
}

.de-san-reg {
  font-size: 19px;
  font-family: PlexSansRegular;
  color: #ffffff;
  text-align: left;
}

.de-btn-selected {
  font-size: 1vmax;
  border: solid #ffffff 1px;
  background-color: #ffffff;
  font-family: PoppinsMedium;
  color: #020001;
  padding: 8px 25px;
  cursor: pointer;
  margin: 25px;
  white-space: nowrap;
}

.de-btn-unselect {
  font-size: 1vmax;
  border: solid #ffffff 1px;
  background-color: #020001;
  font-family: PoppinsMedium;
  color: #ffffff;
  padding: 8px 25px;
  cursor: pointer;
  margin: 25px;
  white-space: nowrap;
}

.de-subbtn-selected {
  font-size: 1vmax;
  font-family: PlexSansRegular;
  color: #ffffff;
  cursor: pointer;
  margin: 25px;
  text-decoration: underline;
  padding-bottom: 2px;
  width: 100px;
}

.de-subbtn-unselect {
  font-size: 1vmax;
  font-family: PlexSansRegular;
  color: #ffffff;
  cursor: pointer;
  margin: 25px;
  text-decoration: none;
  width: 100px;
}

.de-gellery {
  padding: 0px 10%;
}

.de-gallery-image {
  width: 100%;
  object-fit: cover;
  margin: 5px;
  padding: 0px;
}

.shadow-1-strong {
  cursor: pointer;
}

/* -------------------------------------------- Each Project ----------------------------------------------------- */
.N11-Image {
  height: 50vmax;
  /* height: 891px; */
  background-image: url("../src/Data/N11/N11Lo-res/DSC_4093.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.VISANU-Image {
  height: 50vmax;
  /* height: 891px; */
  background-image: url("../src/Data/VISANU/VISANU Lo-res/DSC_6149.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.DULIYA-Image {
  height: 50vmax;
  /* height: 891px; */
  background-image: url("../src/Data/DULIYA/DULIYA Lo-res/duliya02.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.PRIN-Image {
  height: 50vmax;
  /* height: 891px; */
  background-image: url("../src/Data/PRIN/PRIN Lo-res/DSC_0050.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.IDEO-Image {
  height: 50vmax;
  /* height: 891px; */
  background-image: url("../src/Data/IDEO/IDEO Lo-res/DSCF2343-re3_2.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.RAYA-Image {
  height: 50vmax;
  /* height: 891px; */
  background-image: url("../src/Data/RAYA/RAYA Lo-res/raya-reno06.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.INOVE-Image {
  height: 50vmax;
  /* height: 891px; */
  background-image: url("../src/Data/INOVE/INOVE Lo-res/DSCF2104-re.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.JAYHOUSE-Image {
  height: 50vmax;
  /* height: 891px; */
  background-image: url("../src/Data/JAYHOUSE/DSC_2795_resize.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.SENA-Image {
  height: 50vmax;
  /* height: 891px; */
  background-image: url("../src/Data/SENA/0.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}
