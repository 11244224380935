body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #020001;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url(./Data/Fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
    url(./Data/Fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
    url(./Data/Fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "PlexSansRegular";
  src: local("PlexSansRegular"),
    url(./Data/Fonts/PlexSans/IBMPlexSansThai-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "IBMPlexSansThai";
  src: local("IBMPlexSansThai"),
    url(./Data/Fonts/IBMPlexSansThai/IBMPlexSansThai-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "IBMPlexSansThaiBold";
  src: local("IBMPlexSansThaiBold"),
    url(./Data/Fonts/IBMPlexSansThai/IBMPlexSansThai-Bold.ttf)
      format("truetype");
}
