.container-fluid {
  background-color: #020001;
}

.image1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s;
}

.image1:hover {
  color: #8a5d3b;
}

.image1 > div {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.image1 > div > span {
  font-size: 1.5rem;
}

.image2 {
  background-image: url("../../Data/HOME/DSC_0138.jpg");
}

.image3 {
  background-image: url("../../Data/HOME/DSC_4414.jpg");
}

.image4 {
  background-image: url("../../Data/HOME/DSC_6020.jpg");
}

.image5 {
  background-image: url("../../Data/HOME/duliya03.jpg");
}

.image2,
.image3,
.image4,
.image5 {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
  filter: brightness(35%);
  transition: all 0.4s;

  display: flex;
  align-items: flex-end;
  text-decoration: none;
}

.image2:hover,
.image3:hover,
.image4:hover,
.image5:hover {
  filter: brightness(100%);
}

.image2 > div,
.image3 > div,
.image4 > div,
.image5 > div {
  color: #ffffff;
  width: 100%;
  bottom: 0;
  padding: 10px 20px;
  height: 15%;
  text-align: justify;

  font-family: PoppinsSemiBold;
}

.image5:hover {
  cursor: pointer;
}

.carousel {
  padding: 0px !important;
}

.carousel-control-prev {
  display: none;
}

.carousel-control-next {
  display: none;
}

.bm-menu {
  z-index: 5;
}

.section1 {
  height: 50vmax;
  object-fit: cover;
  width: 100% !important;
  /* height: 891px; */
}

.AR2HeaderImage {
  height: 50vmax;
  background-image: url("../../../data/Data/HOME/DJI_0001.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.section2 {
  min-height: 50vmax;
}

.section2_1 {
  min-height: 50vmax;
  /* height: 891px; */
  background-image: url("../../Data/HOME/DSC_4550.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}

.section2_2 {
  background-color: #020001;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section2_2 > div {
  width: 100%;
  text-align: justify;
  margin: 0;
  block-size: fit-content;
}

.section2_2 > div > div {
  padding: 10px 15%;
  text-align: justify;
}

.section3 {
  height: 891px;
  width: 100%;
  background-color: #020001;
}

.section4 {
  height: 670px;
  background-image: url("../../Data/HOME/S__18456729-3.jpg");
  background-repeat: no-repeat;
  background-position: right center;
  background-origin: content-box;
  background-size: 2958px auto;

  display: flex;
  align-items: center;
  transition: all 0.5s;
}

.section4_2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  min-width: 450px;

  position: absolute;
  right: 0;
}

.section4_2 > div {
  margin: 20px 20px;
}

.section4_2_contractLogo {
  height: 80px;
}

.section4_2_4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
  font-family: PoppinsMedium;
  font-size: 17px;
}

.section4_2_4 > div {
  margin: 5px 0px;
}

.section6 {
  height: 890px;
  background-image: url("../../Data/HOME/DSC_2924_resize_2.jpg");
  background-repeat: no-repeat;
  background-position: right 35% center;
  background-origin: content-box;
  background-size: 100% auto;

  display: flex;
  align-items: center;
  transition: all 0.5s;
}

.section6_1 {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgb(0 0 0 / 86%) 0%,
    rgb(0 0 0 / 14%) 100%
  );
}

.section6_1_1 {
  height: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: flex-start;
  flex-direction: column;
}

.section6_1_2 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section6_1_2_1 {
  height: 75%;
  width: 85%;
  max-width: 616px;
  max-height: 616px;
  background-color: rgb(0 0 0 / 66%);
  border-radius: 55px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.paddingLeftTen {
  margin-left: 20%;
}

@media only screen and (max-width: 450px) {
  .section4_2 {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-width: 0px;

    position: absolute;
  }
}

.section5 {
  height: 151px;
  background-color: #020001;
  color: #ffffff;
  font-family: PoppinsLight;
  font-size: 0.2rem;
}

.wraporno {
  white-space: nowrap;
}

@media only screen and (max-width: 321px) {
  .wraporno {
    white-space: normal;
  }
}

@media only screen and (max-width: 400px) {
  .image3,
  .image4,
  .image5 {
    display: none;
  }
}

@media only screen and (max-width: 445px) {
  .section4_2 > div {
    max-width: 80%;
    font-size: 3vw !important;
  }

  .section4_2_contractLogo {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .image4,
  .image5 {
    display: none;
  }

  .image2 > div,
  .image3 > div,
  .image4 > div,
  .image5 > div {
    height: 20%;
  }

  .section3 {
    height: 50vmax;
  }

  .section2_2 {
    padding: 10% 0px !important;
  }
}

@media only screen and (max-width: 869px) {
  .image1 > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .image5 {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .section4_2 {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .section4_2 > div {
    margin: 20px 0px;
    width: 445px;
  }

  .section6 {
    height: auto;
    background-size: auto 100% !important;
  }

  .section6_1_1 {
    height: 50%;
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10%;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .section6_1_2 {
    height: 50%;
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10%;
  }

  .section6_1_2_1 {
    height: 75%;
    width: 85%;
    max-width: 616px;
    max-height: 616px;
    background-color: rgb(0 0 0 / 66%);
    border-radius: 55px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5% 0%;
  }

  .paddingLeftTen {
    margin-left: 5%;
  }
}

@media only screen and (max-width: 1920px) {
  .section6 {
    background-size: 2393px auto;
  }

  /* .section4 {
    background-size: 2393px auto;
  } */
}
