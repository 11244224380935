.card-main {
  text-decoration: none;
  margin-bottom: 5rem;
  display: block;
}

.card-image {
  width: 100%;
  height: 30vmax;
  align-self: center;
  object-fit: cover;
}

.card-header {
  font-size: 2rem;
  font-family: PoppinsSemiBold;
  margin: 20px 0px;
  color: #fff;
  text-align: left;
  padding: 0px;
}

.card-p1 {
  font-size: 23px;
  font-family: PoppinsLight;
  color: #fff;
  text-align: left;
}
