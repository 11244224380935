/* .bur-main {
  border: red solid;
  width: 10%;
  position: fixed;
} */

@media only screen and (min-width: 767px) {
  .bur-main {
    display: none;
  }
}
